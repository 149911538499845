<template>
  <div
    v-infinite-scroll="loadMore"
    infinite-scroll-disabled="busy"
    infinite-scroll-distance="10"
  >
    <r-page page-title="Log Activity">
      <template #page-header>
        <h2 class="page-title">Log Activity</h2>
        <r-menu-tab :level="3" />
      </template>
      <template #action-bar>
        <div class="columns">
          <div class="column is-9">
            <div class="level">
              <div class="level-left">
                <b-button
                  tag="button"
                  class="is-command"
                  icon-left="plus"
                  @click.native="openModalRequest()"
                >
                  New
                </b-button>
              </div>
            </div>
          </div>
          <div class="column is-3">
            <b-field class="search-container">
              <b-input
                expanded
                v-model="search"
                placeholder="Search"
                type="text"
                @keydown.native.enter="searchIconClick"
                icon-right="magnify"
                @icon-right-click="searchIconClick"
                icon-right-clickable
                :loading="isLogActivityPersonalLoading"
              ></b-input>
            </b-field>
          </div>
        </div>
      </template>

      <template #page-content>
        <div
          id="table-request-employment"
          class="table-timeoff-type table-request-personal"
          v-if="currentUser != null"
        >
          <b-table
            :data="logActivityPersonal"
            :per-page="perPage"
            :loading="isLogActivityPersonalLoading"
            @page-change="onPageChange"
            ref:table
            hoverable
            narrowed
            backend-sorting
            :default-sort-direction="defaultSortOrder"
            :default-sort="[sortField, sortOrder]"
            checkbox-position="left"
            class="employment-directory-table table-log-activity-personal general-table"
            @sort="onSort"
            :sticky-header="stickyHeaders"
          >
            <template>
              <b-table-column
                field="adjustment_date"
                label="Date"
                v-slot="props"
                sortable
              >
                <span class="is-capitalize">
                  {{ formatDate(props.row.date) }}
                </span>
              </b-table-column>

              <b-table-column
                field="attendance_start_time"
                label="Clock in/out"
                v-slot="props"
                sortable
              >
                <span class="is-capitalize">
                  {{ formatTime(props.row.oldStartTime, props.row.oldEndTime) }}
                </span>
              </b-table-column>

              <b-table-column
                field="request_clockin_time"
                label="Change To"
                v-slot="props"
                sortable
              >
                <span class="is-capitalize">
                  {{ formatTime(props.row.newStartTime, props.row.newEndTime) }}
                </span>
              </b-table-column>

              <b-table-column
                field="status"
                label="Status"
                v-slot="props"
                sortable
              >
                <span
                  :class="`is-capitalize
                    ${determineFCByStatus(props.row.status)}`"
                >
                  {{ props.row.status }}
                </span>
              </b-table-column>
              <b-table-column
                field="action"
                label="Action"
                v-slot="props"
                centered
              >
                <b-icon
                  icon="pencil"
                  custom-size="mdi-18px"
                  class="click"
                  @click.native="
                    openModalRequest(logActivityPersonal[props.index])
                  "
                ></b-icon>
                <b-icon
                  icon="eye"
                  custom-size="mdi-18px"
                  class="click"
                  @click.native="openSidebar(props.row)"
                ></b-icon>
              </b-table-column>
            </template>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>No data found</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>
        <b-sidebar
          v-if="isRightBarOpen && form != null"
          can-cancel
          fullheight
          :fullwidth="fullWidth"
          :overlay="true"
          right
          v-model="isRightBarOpen"
        >
          <div class="rightbar-detail-top-content-container">
            <div class="right-bar-detail-top-content">
              <p class="has-text-weight-black is-size-4 title">
                Log Activity Details
              </p>
              <b-icon
                icon="close"
                class="x-close-btn"
                custom-size="mdi-18px"
                @click.native="closeSidebar"
              ></b-icon>
              <img
                class="profile-picture"
                :src="determineProfilePicture(currentUser)"
              />

              <p class="has-text-weight-black is-size-4 full-name">
                {{ currentUser.firstName }} {{ currentUser.middleName }}
                {{ currentUser.lastName }}
              </p>
            </div>
            <div class="columns is-multiline p-4 is-size-6">
              <div class="column is-4">Date</div>
              <div class="column is-8 has-text-weight-bold">
                {{ formatDate(selectedColumn.date) }}
              </div>
              <div class="column is-4">Clock in/ out</div>
              <div class="column is-8 has-text-weight-bold">
                {{
                  formatTime(
                    selectedColumn.oldStartTime,
                    selectedColumn.oldEndTime
                  )
                }}
              </div>
              <div class="column is-4">Change to</div>
              <div class="column is-8 has-text-weight-bold">
                {{
                  formatTime(
                    selectedColumn.newStartTime,
                    selectedColumn.newEndTime
                  )
                }}
              </div>
              <div class="column is-4">Status</div>
              <div class="column is-8 has-text-weight-bold is-capitalize">
                {{ selectedColumn.status }}
              </div>
              <div class="column is-12">
                <TimeOffApprovalHistoryComponent
                  :approval-history="selectedColumn.history"
                />
              </div>
              <div
                class="column is-12"
                v-if="selectedColumn.status === 'waiting'"
              >
                <b-button
                  expanded
                  @click="
                    cancelLogActivity(
                      selectedColumn.id,
                      selectedColumn.newStartTime,
                      selectedColumn.newEndTime
                    )
                  "
                >
                  Cancel
                </b-button>
              </div>
            </div>
          </div>
        </b-sidebar>
        <r-page-modal
          :prompt-on-leave="false"
          prompt-message="Are you sure?"
          v-if="isModalOpen"
          @hide="closeModalRequest"
          :is-modal="true"
          key="modalKey"
          class="modal-allowed-capacity"
          remove-scrolling
        >
          <template #page-content>
            <h3 class="modal-title has-text-weight-black">
              {{ isEditing ? 'Detail Change Log' : 'Request Change Log' }}
              <span class="office-pointer-click is-pulled-right">
                <b-icon
                  icon="close"
                  size="is-small"
                  @click.native="closeModalRequest"
                ></b-icon>
              </span>
            </h3>
            <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
              <form
                @submit.prevent="handleSubmit(submit)"
                class="is-label-14px-normal form-timeoff-type"
              >
                <div class="columns is-multiline">
                  <div class="column is-12">
                    <ValidationProvider
                      name="date"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <b-field
                        label="Date"
                        :type="errors[0] && 'is-danger'"
                        :message="errors[0]"
                      >
                        <b-datepicker
                          placeholder="Date"
                          icon-right="calendar"
                          id="date-picker-calendar"
                          :disabled="isEditing"
                          :max-date="maxDate"
                          v-model="form.selectedDate"
                          @input="getDataAttendanceByDate(form.selectedDate)"
                        ></b-datepicker>
                      </b-field>
                    </ValidationProvider>
                  </div>
                  <div class="column is-12">
                    <ValidationProvider
                      name="clock in/ clock out"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <b-field
                        label="Clock in/ Clock Out"
                        :type="errors[0] && 'is-danger'"
                        :message="errors[0]"
                      >
                        <b-select
                          v-model="form.selectedAttendanceOption"
                          expanded
                          placeholder="Clock In/ Clock Out"
                          class="hover"
                          :disabled="isEditing"
                        >
                          <option
                            v-for="option in attendanceOptions"
                            :value="option.id"
                            :key="'attendance-' + option.id"
                          >
                            {{
                              'Clock In (' +
                              formatTimeSelect(option.startTime) +
                              ') - ' +
                              'Clock Out (' +
                              formatTimeSelect(option.endTime) +
                              ')'
                            }}
                          </option>
                        </b-select>
                      </b-field>
                    </ValidationProvider>
                  </div>
                  <div class="column is-6">
                    <ValidationProvider
                      name="start time"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <b-field
                        label="Start Time"
                        :type="errors[0] && 'is-danger'"
                        :message="errors[0]"
                      >
                        <b-timepicker
                          hour-format="24"
                          placeholder="e.g 08:00"
                          icon-right="clock"
                          v-model="form.formattedStartTime"
                          :disabled="isEditing && form.status !== 'waiting'"
                        ></b-timepicker>
                      </b-field>
                    </ValidationProvider>
                  </div>
                  <div class="column is-6">
                    <ValidationProvider
                      name="end time"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <b-field
                        label="End Time"
                        :type="errors[0] && 'is-danger'"
                        :message="errors[0]"
                      >
                        <b-timepicker
                          hour-format="24"
                          placeholder="e.g 12:00"
                          icon-right="clock"
                          v-model="form.formattedEndTime"
                          :disabled="isEditing && form.status !== 'waiting'"
                        ></b-timepicker>
                      </b-field>
                    </ValidationProvider>
                  </div>
                  <div class="column is-12">
                    <b-button
                      v-if="selectedColumn"
                      expanded
                      native-type="submit"
                      type="is-primary"
                      :loading="isSubmitting"
                      :disabled="isEditing && form.status !== 'waiting'"
                    >
                      {{ isEditing ? 'Update' : 'Request Change Log' }}
                    </b-button>

                    <b-button
                      v-else
                      expanded
                      native-type="submit"
                      type="is-primary"
                      :loading="isSubmitting"
                    >
                      {{ 'Request Change Log' }}
                    </b-button>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </template>
        </r-page-modal>
      </template>
    </r-page>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  determineFontColorByStatus,
  formatDate,
  showToast,
} from '@/services/util'
import ApiService from '@/services/common/api.service'
import moment from 'moment-timezone'
import TimeOffApprovalHistoryComponent from '../TimeOff/TimeOffApprovalHistoryComponent.vue'

export default {
  components: { TimeOffApprovalHistoryComponent },
  data() {
    const today = new Date()
    return {
      // offsetDiff in hour
      offSetDiff: this.getOffsetDiff(),
      stickyHeaders: true,
      isPaginated: false,
      perPage: 20,
      page: 0,
      lastPage: 0,
      total: 0,

      defaultSortOrder: 'desc',
      sortField: 'created_at',
      sortOrder: 'desc',
      search: '',

      isLogActivityPersonalLoading: false,
      isLoadMore: false,
      isModalOpen: false,
      isEditing: false,
      selectedColumn: null,
      isRightBarOpen: false,
      fullWidth: false,
      right: false,

      data: {
        id: '',
        name: null,
        paid_days: null,
        time_type: null,
        createdAt: null,
        updatedAt: null,
      },

      form: {
        id: null,
        selectedAttendanceOption: null,
        selectedDate: null,
        formattedStartTime: null,
        formattedEndTime: null,
        status: null,
      },

      startTime: null,
      endTime: null,

      attendanceOptions: [],
      maxDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
      isSubmitting: null,
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      logActivityPersonal: 'logActivity/getLogActivityPersonal',
    }),
  },
  methods: {
    ...mapActions({
      actionLoadLogActivityPersonal: 'logActivity/fetchLogActivityPersonal',
    }),

    ...mapMutations({
      setLogActivityPersonal: 'logActivity/setLogActivityPersonal',
    }),
    getOffsetDiff() {
      // if you live in asia/jakarta, offset will be 420 (7 * 60 minute)
      let offsetLocal = moment.tz(new Date(), moment.tz.guess()).utcOffset()
      // if you use timezone asia/tokyo, offset will be 540 (9 * 60)
      let offSetTimeZone = moment(new Date()).utcOffset()

      let diff = offsetLocal - offSetTimeZone
      if (diff < 0) {
        diff *= -1
      }
      // convert from minutes to hours
      diff /= 60
      return diff
    },
    async getDataAttendanceByDate(selectedDate) {
      let formattedDate = formatDate(selectedDate)

      try {
        if (selectedDate != null) {
          let response = await ApiService.get(
            `/api/space-roketin/attendance/log?start_date=${formattedDate}&end_date=${formattedDate}`
          )
          this.attendanceOptions = response.data.data[0].data
        } else {
          this.attendanceOptions = []
        }
      } catch (e) {
        console.log(e)
      }
    },

    formatTimeSelect(str) {
      return moment(moment.utc(str).local().toISOString()).format('HH:mm')
    },

    openModalRequest(row) {
      if (row) {
        this.isEditing = true
        this.form.id = row.id
        this.form.selectedDate = new Date(row.date)
        this.getDataAttendanceByDate(this.form.selectedDate)

        this.form.formattedStartTime = new Date(
          moment(row.newStartTime).format('YYYY-MM-DD HH:mm:ss')
        )
        this.form.formattedEndTime = new Date(
          moment(row.newEndTime).format('YYYY-MM-DD HH:mm:ss')
        )

        this.form.selectedAttendanceOption = row.attendanceId
        this.form.status = row.status
      }
      this.isModalOpen = true
    },

    closeModalRequest() {
      this.resetForm()
      this.isModalOpen = false
      this.isEditing = false
    },

    async submit() {
      try {
        let momentStartTime = moment(this.form.formattedStartTime)
        let startTimeUTC = momentStartTime
          .utc()
          .subtract(this.offSetDiff, 'hours')

        let momentEndTime = moment(this.form.formattedEndTime)
        let endTimeUTC = momentEndTime.utc().subtract(this.offSetDiff, 'hours')
        if (!this.isEditing) {
          let formRequest = new FormData()
          formRequest.append(
            'attendance_id',
            this.form.selectedAttendanceOption
          )

          formRequest.append('start_time', startTimeUTC.format('HH:mm:ss'))
          formRequest.append('end_time', endTimeUTC.format('HH:mm:ss'))
          let response = await ApiService.post(
            '/api/space-roketin/v3/request/adjustment',
            formRequest,
            true
          )
          this.form = {
            attendance_id: '',
            start_time: null,
            end_time: null,
          }
          this.setLogActivityPersonal([
            ...this.logActivityPersonal,
            response.data.data,
          ])
          this.closeModalRequest()
          showToast('Add Success', 'is-success', 'is-bottom')
        } else {
          let formRequest = new FormData()
          formRequest.append(
            'attendance_id',
            this.form.selectedAttendanceOption
          )

          formRequest.append('start_time', startTimeUTC.format('HH:mm:ss'))
          formRequest.append('end_time', endTimeUTC.format('HH:mm:ss'))
          formRequest.append('_METHOD', 'PUT')

          await ApiService.post(
            '/api/space-roketin/v3/request/adjustment/' + this.form.id,
            formRequest,
            true
          )

          // //for table ---------------------------------------------------------
          let logActivityPersonalVue = [...this.logActivityPersonal]

          let index = logActivityPersonalVue.findIndex(
            (t) => t.id === this.form.id
          )

          if (index >= 0) {
            logActivityPersonalVue[
              index
            ].newStartTime = startTimeUTC.toISOString()
            logActivityPersonalVue[index].newEndTime = endTimeUTC.toISOString()
          }
          this.setLogActivityPersonal([...logActivityPersonalVue])
          this.closeModalRequest()
          showToast('Edit Success', 'is-success', 'is-bottom')
        }
      } catch (e) {
        console.log(e)
        if (e.response?.data?.message) {
          showToast(e.response.data.message, 'is-danger', 'is-bottom')
        }
      }
    },

    determineFCByStatus(status) {
      return determineFontColorByStatus(status)
    },

    formatDate(str) {
      if (str) {
        return moment(str).format('DD MMMM YYYY')
      }
    },

    formatTime(start, end) {
      const startTime = moment(start).format('HH:mm')
      const endTime = moment(end).format('HH:mm')
      return `${startTime}-${endTime}`
    },

    async loadMore() {
      if (this.page < this.lastPage) {
        this.isLoadMore = true
        await this.loadLogActivityPersonalList()
        this.isLoadMore = false
      }
    },

    async searchIconClick() {
      await this.resetState()
    },

    async resetState() {
      this.page = 0
      this.lastPage = 0
      this.setLogActivityPersonal([])
      await this.loadLogActivityPersonalList()
    },

    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.resetState()
    },

    onPageChange(page) {
      this.page = page
      this.loadLogActivityPersonalList()
    },

    resetForm() {
      this.form = {
        selectedAttendanceOption: '',
        startTime: null,
        endTime: null,
      }
    },

    openSidebar(changeSchedule) {
      this.selectedColumn = changeSchedule
      this.isRightBarOpen = true
    },

    closeSidebar() {
      this.isRightBarOpen = false
    },

    determineProfilePicture(user) {
      if (!user.profilePictureUrl) {
        if (user.gender === 'M') {
          // male
          return '/images/default-profile-picture-male.png'
        }
        // female
        return '/images/default-profile-picture-female.png'
      }

      return user.profilePictureUrl
    },

    async cancelLogActivity(id, startTime, endTime) {
      let momentStartTime = moment(startTime)
      let startTimeUTC = momentStartTime
        .utc()
        .subtract(this.offSetDiff, 'hours')

      let momentEndTime = moment(endTime)
      let endTimeUTC = momentEndTime.utc().subtract(this.offSetDiff, 'hours')
      let res = await this.$swal({
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'button is-primary',
          cancelButton: 'button',
        },
        text: `Are you sure? You can't undo this action.`,
      })

      if (res && res.isConfirmed) {
        const form = new FormData()

        form.append('start_time', startTimeUTC.format('HH:mm:ss'))
        form.append('end_time', endTimeUTC.format('HH:mm:ss'))
        form.append('status', 'canceled')
        this.isSubmitting = true
        try {
          form.append('_METHOD', 'PUT')
          await ApiService.post(
            '/api/space-roketin/v3/request/adjustment/' + id,
            form,
            true
          ).then((response) => {
            if (response.status === 200) {
              // for table ---------------------------------------------------------
              let logActivityPersonalVue = [...this.logActivityPersonal]

              let index = logActivityPersonalVue.findIndex((t) => t.id === id)

              if (index >= 0) {
                logActivityPersonalVue[index] = response.data.data
              }
              this.setLogActivityPersonal([...logActivityPersonalVue])
            }
          })

          this.closeSidebar()
          showToast('Request canceled', 'is-success', 'is-bottom')
          this.isRightBarOpen = false
        } catch (e) {
          showToast(e.response.message, 'is-danger', 'is-bottom')
        }
      }
    },

    async loadLogActivityPersonalList() {
      this.isLogActivityPersonalLoading = true
      try {
        const response = await this.actionLoadLogActivityPersonal({
          perPage: this.perPage,
          page: ++this.page,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
          search: this.search,
        })

        this.total = response.data.meta.total
        this.lastPage = response.data.meta.lastPage
      } catch (err) {
        console.log(err)
      }
      this.isLogActivityPersonalLoading = false
    },
  },
  async mounted() {
    this.setLogActivityPersonal([])
    await this.loadLogActivityPersonalList()
  },

  watch: {
    currentUser() {
      // get offset difference local - timezoned  based on timezone
      this.offSetDiff = this.getOffsetDiff()
    },
  },
}
</script>
